<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col v-if="is_empty(consumer_card_title)" :span="6">
                            <el-form-item label="消费卡标题">
                                <el-input v-model="search.consumer_card_title"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="用户昵称">
                                <el-input v-model="search.user_nick"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="用户手机号">
                                <el-input v-model="search.phone"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3><span
                        v-if="!is_empty(consumer_card_title)">【{{ consumer_card_title }}】</span>{{ page_name }}</h3>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button v-if="!is_empty(this.$route.query.consumer_card_uuid)" size="medium" type="primary" @click="importList">导出
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    size="medium"
                    border
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="consumer_card_qrcode"
                        label="领取二维码"
                        width="130">
                    <template slot-scope="scope">
                        <viewer v-if="!is_empty(scope.row.consumer_card_qrcode)">
                            <el-avatar shape="square" :size="22" :src="scope.row.consumer_card_qrcode"></el-avatar>
                        </viewer>
                        <div v-else>
                            <el-button
                                    v-if="is_auth('consumer.receive.saveqrcode')"
                                    @click="get_qrcode(scope.row.card_receive_uuid)"
                                    :disabled="scope.row.consumer_card_state!==1"
                                    type="primary" size="mini">领取二维码
                            </el-button>
                            <span v-else>-</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="consumer_card_title"
                        label="消费卡名称"
                        width="130">
                </el-table-column>
                <el-table-column
                        prop="consumer_card_number"
                        label="卡号"
                        width="130">
                </el-table-column>
                <el-table-column
                        prop="consumer_card_qrcode"
                        label="用户昵称"
                        width="130">
                    <template slot-scope="scope">
                        <el-image
                                v-if="!is_empty(scope.row.user_nick)"
                                style="width: 22px; height: 22px; margin-right: 4px;"
                                :src="scope.row.avatar_url"
                                fit="contain">
                        </el-image>
                        {{ scope.row.user_nick ? scope.row.user_nick : '-' }}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="phone"
                        label="用户手机号"
                        width="180">
                    <template slot-scope="scope">
                        <span v-if="!is_empty(scope.row.phone)"> {{ scope.row.phone }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="receive_card_amount"
                        label="消费卡金额">
                    <template slot-scope="scope">
                        ￥{{ scope.row.receive_card_quota }}/￥{{ scope.row.receive_card_amount }}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="start_time"
                        label="消费卡有效期">
                    <template slot-scope="scope">
                        ￥{{ scope.row.start_time }}/{{ scope.row.end_time }}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="receive_time"
                        label="领取时间"
                        width="130">
                    <template slot-scope="scope">
                        <span v-if="!is_empty(scope.row.phone)"> {{ scope.row.receive_time }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="state"
                        label="状态"
                        width="180">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state===1" type="success">正常</el-tag>
                        <el-tag size="mini" v-if="scope.row.state===2" type="warning">停用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="150">
                    <template slot-scope="scope">
                        <el-button
                            v-if="is_auth('consumer.receive.isdisable') && scope.row.state===1"
                            @click="operation_tip(scope.row,'isdisable')"
                            :disabled="scope.row.consumer_card_state!==1"
                            type="warning" size="mini">禁用
                        </el-button>
                        <el-button
                            v-if="is_auth('consumer.receive.isenable') && scope.row.state===2"
                            @click="operation_tip(scope.row,'isenable')"
                            :disabled="scope.row.consumer_card_state!==1"
                            type="primary" size="mini">启用
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>


        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            consumer_card_title: '',        // 消费卡名称
            consumer_card_uuid: '',        // 消费卡id
            page_name: '消费卡领取列表',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容

            info: {},
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                consumer_card_title: '',
                user_nick: '',
                phone: '',
            }

            let consumer_card_uuid = this.$route.query.consumer_card_uuid
            let consumer_card_title = this.$route.query.consumer_card_title
            if (consumer_card_uuid !== undefined) {
                this.consumer_card_uuid = consumer_card_uuid
                this.consumer_card_title = consumer_card_title
            }
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "consumer.receive.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);

            if (this.consumer_card_uuid != '') {
                postdata.consumer_card_uuid = this.consumer_card_uuid
            }

            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 导出数据
        importList(){
            let postdata = {
                api_name: "consumer.receive.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
                export: "yes",
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            if (this.consumer_card_uuid != '') {
                postdata.consumer_card_uuid = this.consumer_card_uuid
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (res) => {
                this.loading = false
                 if(res.code === 10005){
                    this.Tool.errormes(res);
                }else{
                    location.href = res;
                }
            })
        },
        // 获取二维码
        get_qrcode(card_receive_uuid = '') {
            let postdata = {
                api_name: "consumer.receive.saveqrcode",
                token: this.Tool.get_l_cache('token'),
                card_receive_uuid,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '二维码获取成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(info, enable = '') {
            let tip = ''
            let options = {}
            // 启用
            if (enable === 'isenable') {
                tip = '启用【' + info.consumer_card_title + ':' + info.consumer_card_number + '】？'
            }
            // 禁用
            if (enable === 'isdisable') {
                tip = '禁用【' + info.consumer_card_title + ':' + info.consumer_card_number + '】？'
            }
            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                .then(() => {
                    this.isoperation(info.card_receive_uuid, enable)
                })
                .catch(() => {
                });
        },
        // 操作
        isoperation(card_receive_uuid = '', enable = '') {
            let postdata = {
                api_name: "consumer.receive." + enable,
                token: this.Tool.get_l_cache('token'),
                card_receive_uuid,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}

</style>
